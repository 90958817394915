<template>
  <div class="same-detail">
		<div class="same-detail-nav">
			<div class="detail-nav-icon"></div>
			<div class="detail-nav">
				<span>当前位置：</span>
				<span class="pointer" @click="toLink('/dashboard', 0)">首页 > </span>
				<span class="pointer" @click="toLink(`/carsite/same-list/${$route.query.pageCode}/list`, $route.query.index)">{{pageConfigData[$route.query.pageCode] && pageConfigData[$route.query.pageCode].title}} > </span>
				<span>{{sameDetailNewData.title}}</span>
			</div>
		</div>
		<div class="same-detail-top">
			<div class="same-detail-top-title">
				{{sameDetailNewData.title}}
			</div>
			<div class="same-detail-top-time">
				<div class="detail-top-time">
					<span>{{sameDetailNewData.creationTime}}</span>
					<span>作者：{{sameDetailNewData.author}} </span>
					<span>来源：{{sameDetailNewData.source}}</span>
				</div>
			</div>
		</div>
		<div class="same-detail-content">
			<div class="ql-wrap">
				<div class="ql-snow">
					<div class="ql-editor" v-html="sameDetailNewData.content"></div>
				</div>
				<p v-if="sameDetailNewData.header" style="font-size: 14px;margin: 35px 30px 0 0;text-align: right;">
					图：<span>{{ sameDetailNewData.header }}</span>
				</p>
				<p v-if="sameDetailNewData.footer" style="font-size: 14px;text-align: right;margin-right: 30px;">
					文：<span>{{ sameDetailNewData.footer }}</span>
				</p>
			</div>
			<div class="same-qbu">
				<span class="same-qbu-up" v-if="sameDetailUpData" @click="toDetail(sameDetailUpData)">上一篇: {{sameDetailUpData.title}}</span>
				<span class="same-qbu-down" v-if="sameDetailDownData" @click="toDetail(sameDetailDownData)">下一篇 {{sameDetailDownData.title}}</span>
			</div>
		</div>
  </div>
</template>

<script>
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { pageConfigData } from './config'
import { mapState } from "vuex";
export default {
  name: "sameListDetail",
  components: {
  },
  data() {
    return {
			pageConfigData
    };
  },
  computed: {
    ...mapState({
			sameDetailDownData: state => {
				return state.sameList.sameDetailDownData;
			},
			sameDetailUpData: state => {
				return state.sameList.sameDetailUpData;
			},
			sameDetailNewData: state => {
				return state.sameList.sameDetailNewData;
			},
    })
  },
  activated() {
    this.fetch();
  },
  methods: {
		toLink(path, index) {
      this.$router.push({
        path,
				query: {
					index
				}
      });
    },
		toDetail(selectItem) {
      this.$router.push({
        path: `/carsite/same-list/${this.$route.query.pageCode}/detail`,
				query: {
					id: selectItem.id,
					index: this.$route.query.index,
					pageCode: this.$route.query.pageCode
				}
      });
			this.fetch(true)
    },
    fetch(flag) {
      this.$store.dispatch("sameList/getListDetail", { ...this.$route.query }).then(() => {
				if(flag){
					document.querySelector('#mainContainer').scrollTop = 0
				}
				const ViewerDom = this.$refs.imgView;
				const viewer = new Viewer(ViewerDom, {
					// inline: true,
					title: false,
					fullscreen: false,
					// zoomRatio: 3,
					// zIndexInline: 10,
					// url: 'data-original',
					// viewed () {
					// 	viewer.zoomTo(0.6)
					// }
				})
			})
    },
  }
};
</script>

<style lang="less" scoped>
.same-detail{
	margin: 0 auto;
	width: 93%;
	max-width: 1200px;
	.pointer {
		cursor: pointer;
	}
	.same-detail-nav{
		text-align: right;
    padding-left: 20px;
    font-size: 14px;
    color: rgb(102 102 102);
    vertical-align: middle;
		margin-top: 25px;
    margin-bottom: 40px;
	}
	.same-detail-top-title{
		text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
    color: rgb(18, 58, 137);
	}
	.same-detail-top-time{
		font-size: 14px;
    color: rgb(153, 153, 153);
		text-align: center;
		.detail-top-time{
			display: inline-block;
		}
		span{
			padding-right: 45px;
			&:last-child{
				padding-right: 0;
			}
		}
	}
	.same-detail-content{
		margin-top: 30px;
    font-size: 18px;
    line-height: 36px;
    color: rgb(102, 102, 102);
		.ql-editor{
			line-height: 36px;
			font-size: 16px;
			p{
				font-size: 16px;
			}
		}
	}
	.same-qbu{
		margin-top: 50px;
    border-bottom: 1px solid rgb(193, 193, 193);
    border-top: 1px solid rgb(193, 193, 193);
		overflow: hidden;
		>span{
			display: inline-block;
			width: 49%;
			vertical-align: middle;
			font-size: 18px;
			color: rgb(153 153 153);
			line-height: 65px;
			cursor: pointer;
			&:hover{
				color: rgb(2, 36, 123);
			}
		}
		.same-qbu-up{
			float: left;
		}
		.same-qbu-down{
			float: right;
			text-align: right;
		}
	}
}
</style>